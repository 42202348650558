// extracted by mini-css-extract-plugin
export var active = "bx_bd";
export var categories = "bx_c1";
export var category = "bx_c2";
export var description = "bx_dk";
export var featured = "bx_c5";
export var icon = "bx_bv";
export var input = "bx_cV";
export var mobileVidContainer = "bx_gF";
export var mobileVideo = "bx_gG";
export var mobileVideoThumbnail = "bx_gH";
export var mobileVideoTitle = "bx_gJ";
export var mobileVidsContainer = "bx_gD";
export var moreVideo = "bx_gy";
export var moreVideoContainer = "bx_gx";
export var moreVideoThumbnail = "bx_gz";
export var moreVideoTitle = "bx_gB";
export var moreVideos = "bx_gp";
export var moreVideosHeader = "bx_gn";
export var moreVideosSlider = "bx_gw";
export var moreVideosSliderMobile = "bx_gv";
export var scrollArrow = "bx_gr";
export var scrollButton = "bx_gq";
export var scrollLeft = "bx_gs";
export var scrollRight = "bx_gt";
export var search = "bx_cT";
export var section = "bx_j";
export var sectionTitle = "bx_gm";
export var selector = "bx_gC";
export var selectors = "bx_ff";
export var video = "bx_gk";
export var videoContainer = "bx_gl";