// extracted by mini-css-extract-plugin
export var container = "bH_b";
export var feature = "bH_cp";
export var featureText = "bH_cq";
export var features = "bH_cm";
export var footer = "bH_cs";
export var footerText = "bH_ct";
export var icon = "bH_bv";
export var section = "bH_j";
export var text = "bH_cb";
export var title = "bH_g";
export var waveOne = "bH_q";
export var waveThree = "bH_ch";
export var waveTwo = "bH_n";
export var waves = "bH_cg";