// extracted by mini-css-extract-plugin
export var buttons = "bd_cf";
export var container = "bd_b";
export var image = "bd_k";
export var innerText = "bd_dW";
export var logo = "bd_bn";
export var p = "bd_dX";
export var section = "bd_j";
export var short = "bd_dY";
export var tall = "bd_dZ";
export var textContainer = "bd_bN";