// extracted by mini-css-extract-plugin
export var caption = "bw_gj";
export var grey = "bw_dG";
export var heading = "bw_G";
export var image = "bw_k";
export var left = "bw_bH";
export var reverse = "bw_dC";
export var right = "bw_bJ";
export var section = "bw_j";
export var video = "bw_gk";
export var videoContainer = "bw_b7";
export var videoWrapper = "bw_b8";