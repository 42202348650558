// extracted by mini-css-extract-plugin
export var container = "M_b";
export var content = "M_bZ";
export var icon = "M_bv";
export var iconContainer = "M_bQ";
export var icons = "M_bT";
export var image = "M_k";
export var label = "M_cB";
export var section = "M_j";
export var text = "M_cb";
export var title = "M_g";