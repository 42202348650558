// extracted by mini-css-extract-plugin
export var background = "bD_x";
export var backgroundContainer = "bD_fn";
export var bottomWave = "bD_cS";
export var buttons = "bD_cf";
export var container = "bD_b";
export var content = "bD_bZ";
export var icon = "bD_bv";
export var iconContainer = "bD_bQ";
export var middleWave = "bD_cR";
export var negativeTop = "bD_g4";
export var overlap = "bD_g6";
export var section = "bD_j";
export var selected = "bD_bg";
export var tab = "bD_dV";
export var tabText = "bD_dT";
export var title = "bD_g";
export var topWave = "bD_g5";