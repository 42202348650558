// extracted by mini-css-extract-plugin
export var active = "bf_bd";
export var arrow = "bf_9";
export var button = "bf_b5";
export var buttonMobile = "bf_d7";
export var column = "bf_dc";
export var columns = "bf_db";
export var container = "bf_b";
export var description = "bf_dk";
export var disclaimer = "bf_cN";
export var disclaimerButton = "bf_d3";
export var disclaimerContainer = "bf_d9";
export var disclaimerText = "bf_fd";
export var fadeBottom = "bf_fc";
export var fadeTop = "bf_fb";
export var featureFooter = "bf_d5";
export var footer = "bf_cs";
export var footerMobile = "bf_d6";
export var heading = "bf_G";
export var highlight = "bf_d4";
export var icon = "bf_bv";
export var iconContainer = "bf_bQ";
export var left = "bf_bH";
export var padding = "bf_dz";
export var popular = "bf_dy";
export var prefix = "bf_d2";
export var recommend = "bf_d0";
export var recommended = "bf_d1";
export var right = "bf_bJ";
export var section = "bf_j";
export var show = "bf_L";
export var subHeading = "bf_H";
export var suffix = "bf_dw";
export var title = "bf_g";
export var waveContainer = "bf_d8";
export var waveOne = "bf_q";
export var waveThree = "bf_ch";
export var waveTwo = "bf_n";
export var waves = "bf_cg";