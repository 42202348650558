// extracted by mini-css-extract-plugin
export var background = "bq_x";
export var backgroundContainer = "bq_fn";
export var bottomImage = "bq_t";
export var bottomImageContainer = "bq_fB";
export var buttons = "bq_cf";
export var container = "bq_b";
export var imagesContainer = "bq_fz";
export var middleWave = "bq_cR";
export var section = "bq_j";
export var text = "bq_cb";
export var textContainer = "bq_bN";
export var title = "bq_g";
export var topImage = "bq_m";
export var waveOverlay = "bq_fy";