// extracted by mini-css-extract-plugin
export var bottomContainer = "D_cd";
export var buttons = "D_cf";
export var icon = "D_bv";
export var link = "D_bt";
export var section = "D_j";
export var social = "D_cc";
export var text = "D_cb";
export var title = "D_g";
export var topContainer = "D_b9";
export var videoContainer = "D_b7";
export var videoWrapper = "D_b8";