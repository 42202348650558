// extracted by mini-css-extract-plugin
export var buttons = "bc_cf";
export var container = "bc_b";
export var content = "bc_bZ";
export var icon = "bc_bv";
export var iconContainer = "bc_bQ";
export var image = "bc_k";
export var section = "bc_j";
export var selected = "bc_bg";
export var tab = "bc_dV";
export var tabText = "bc_dT";