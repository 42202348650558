// extracted by mini-css-extract-plugin
export var buttons = "R_cf";
export var column = "R_dc";
export var columns = "R_db";
export var container = "R_b";
export var down = "R_df";
export var download = "R_dd";
export var footer = "R_cs";
export var information = "R_c9";
export var section = "R_j";
export var show = "R_L";
export var text = "R_cb";
export var title = "R_g";
export var units = "R_dg";
export var up = "R_dj";
export var upload = "R_dh";
export var waveOne = "R_q";
export var waveThree = "R_ch";
export var waveTwo = "R_n";
export var waves = "R_cg";