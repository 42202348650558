// extracted by mini-css-extract-plugin
export var answer = "by_gR";
export var arrow = "by_9";
export var body = "by_c";
export var category = "by_c2";
export var contactIcons = "by_gK";
export var current = "by_fr";
export var fade = "by_gN";
export var footer = "by_cs";
export var footerText = "by_ct";
export var icon = "by_bv";
export var iconContainer = "by_bQ";
export var issue = "by_gQ";
export var issueContainer = "by_gM";
export var issueText = "by_gP";
export var issues = "by_gL";
export var left = "by_bH";
export var padding = "by_dz";
export var right = "by_bJ";
export var section = "by_j";
export var selectors = "by_ff";
export var subtitle = "by_cy";
export var title = "by_g";