// extracted by mini-css-extract-plugin
export var bottom = "bt_s";
export var contact = "bt_fL";
export var faqCat = "bt_fT";
export var faqCatDescription = "bt_fX";
export var faqCatTitle = "bt_fW";
export var faqCategories = "bt_fS";
export var faqIcon = "bt_fV";
export var footerText = "bt_ct";
export var icon = "bt_bv";
export var iconContainer = "bt_bQ";
export var iconText = "bt_fN";
export var immediate = "bt_fM";
export var inputContainer = "bt_c3";
export var linksSubtitle = "bt_fQ";
export var linksTitle = "bt_fP";
export var search = "bt_cT";
export var searchInput = "bt_c4";
export var section = "bt_j";
export var selected = "bt_bg";
export var supportButton = "bt_fY";
export var supportLinks = "bt_fR";
export var top = "bt_l";
export var wave = "bt_fK";