// extracted by mini-css-extract-plugin
export var arrow = "bh_9";
export var body = "bh_c";
export var circle = "bh_fl";
export var customer = "bh_fk";
export var feedback = "bh_fj";
export var feedbacks = "bh_fg";
export var footer = "bh_cs";
export var footerText = "bh_ct";
export var left = "bh_bH";
export var nameLocation = "bh_fm";
export var padding = "bh_dz";
export var right = "bh_bJ";
export var section = "bh_j";
export var selected = "bh_bg";
export var selectors = "bh_ff";
export var subtitle = "bh_cy";
export var text = "bh_cb";
export var title = "bh_g";
export var wrapper = "bh_fh";