// extracted by mini-css-extract-plugin
export var body = "br_c";
export var buttons = "br_cf";
export var container = "br_b";
export var icon = "br_bv";
export var icons = "br_bT";
export var image = "br_k";
export var items = "br_fD";
export var label = "br_cB";
export var mobileButtons = "br_fF";
export var name = "br_fC";
export var section = "br_j";
export var selected = "br_bg";
export var show = "br_L";
export var title = "br_g";