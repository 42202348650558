// extracted by mini-css-extract-plugin
export var bottomWave = "bn_cS";
export var button = "bn_b5";
export var buttonContainer = "bn_N";
export var buttonFooter = "bn_ft";
export var container = "bn_b";
export var middleWave = "bn_cR";
export var section = "bn_j";
export var subtitle = "bn_cy";
export var textContainer = "bn_bN";
export var title = "bn_g";