// extracted by mini-css-extract-plugin
export var active = "bs_bd";
export var categories = "bs_c1";
export var category = "bs_c2";
export var icon = "bs_bv";
export var icons = "bs_bT";
export var image = "bs_k";
export var member = "bs_fH";
export var memberTitle = "bs_fJ";
export var name = "bs_fC";
export var placeholder = "bs_bb";
export var section = "bs_j";
export var show = "bs_L";
export var subtitle = "bs_cy";
export var team = "bs_fG";