// extracted by mini-css-extract-plugin
export var bottomCircle = "bz_gT";
export var content = "bz_bZ";
export var description = "bz_dk";
export var hide = "bz_z";
export var icon = "bz_bv";
export var image = "bz_k";
export var imageContainer = "bz_gY";
export var left = "bz_bH";
export var nextYear = "bz_gX";
export var right = "bz_bJ";
export var section = "bz_j";
export var shrink = "bz_gW";
export var title = "bz_g";
export var topCircle = "bz_gS";
export var wave = "bz_fK";
export var waveContainer = "bz_d8";
export var year = "bz_gV";