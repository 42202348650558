// extracted by mini-css-extract-plugin
export var appStores = "T_dF";
export var grey = "T_dG";
export var icon = "T_bv";
export var iconGroup = "T_dD";
export var image = "T_k";
export var left = "T_bH";
export var reverse = "T_dC";
export var right = "T_bJ";
export var section = "T_j";
export var textSection = "T_dB";