// extracted by mini-css-extract-plugin
export var buttons = "bC_cf";
export var left = "bC_bH";
export var media = "bC_g3";
export var right = "bC_bJ";
export var section = "bC_j";
export var selected = "bC_bg";
export var signature = "bC_g2";
export var testimonial = "bC_g1";
export var text = "bC_cb";
export var textContainer = "bC_bN";
export var video = "bC_gk";